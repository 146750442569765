// File generated automatically, do not modify

export interface Adres {
  kraj?: string;
  miasto?: string;
  kodTeryt?: string;
  kodTerytTerc?: string;
  kodTerytUlic?: string;
  ulica?: string;
  kodPocztowy?: string;
  numerDomu?: string;
  adresDoKorespondencji?: boolean;
}

export interface CertCacheEntryDto {
  thumbprint?: string;
  typ?: TypCertyfikatu;
  friendlyName?: string;
  subject?: string;
  dataOd: string;
  dataDo: string;
}

export interface Certyfikat {
  nazwaCertyfikatu?: string;
  base64?: string;
  cacheThumbprint?: string;
}

export interface DaneDoAnulowaniaRecepty {
  certyfikatDoPodpisu?: Certyfikat;
  idRecepty?: number;
  identyfikatorZewnetrzny?: IdentyfikatorZewnetrzny;
  osobaWystawiajaca?: PersonelMedyczny;
  asystentMedyczny?: PersonelMedyczny;
  jednostkaOrganizacyjna?: JednostkaOrganizacyjna;
  pacjent?: Pacjent;
  uzasadnienie?: string;
}

export interface DaneDoWystawieniaRecepty {
  rodzajLeku?: ReceptyRodzajLeku;
  kategoriaDostepnosciLeku?: ReceptyKategoriaDostepnosciLeku;
  poziomOdplatnosci?: ReceptyPoziomOdplatnosciZaLeki;
  zezwalajNaZamienniki?: boolean;
  pielegniarskaNaZlecenieLekarza?: boolean;
  identyfikatorZewnetrzny?: IdentyfikatorZewnetrzny;
  kodEan?: string;
  opisProduktu?: ReceptyOpisProduktu;
  iloscLeku: number;
  iloscLekuJednostka?: string;
  dawkowanie?: string;
  nazwaReceptury?: string;
  skladnikiReceptury?: ReceptySkladnikReceptury[];
  sposobPrzygotowaniaReceptury?: string;
  infoDlaWydajacego?: string;
  odroczonaDataRealizacji?: string;
  waznaPrzezRok?: boolean;
  pilne?: boolean;
  identyfikatorWinoskuZamowieniaRecepty?: string;
  potwierdzenieIlosciSubstancjiCzynnej?: ReceptyPotwierdzenieIlosciSubstancjiCzynnej[];
  iloscSubstancjiPsychotropowej?: number;
  iloscSubstancjiPsychotropowejJednostka?: string;
  iloscSubstancjiAktywnejRpw?: number;
  iloscSubstancjiAktywnejRpwJednostka?: string;
}

export enum DrogaKorespondencji {
  Papierowa = 1,
  Elektroniczna = 2,
}

export interface EPuapKontekstWywolaniaEPuapCertyfikatIntegratora {
  typCertyfikatu?: string;
  certyfikatBase64?: string;
  haslo?: string;
  entryId?: string;
  entryPass?: string;
}

export interface EReceptaMigrationSourceModule {
  sourceUrl?: string;
  instanceName?: string;
  preferred?: boolean;
}

export interface EReceptaMigrationStartParams {
  eReceptaSourceUrl?: string;
  recepty?: boolean;
  skierowania?: boolean;
}

export interface EReceptaMigrationStatus {
  isRunning?: boolean;
  isFinished?: boolean;
  startDate?: string;
  endDate?: string;
  progressMessage?: string;
  receptyTotalCount: number;
  receptySkippedDueToAlreadyMigrated: number;
  receptyMigrated: number;
  receptyMigrationErrorCount: number;
  receptyStartDate?: string;
  receptyEndDate?: string;
  receptyEstimatdEnd?: string;
  receptyPerMinute?: number;
  skierowaniaTotalCount: number;
  skierowaniaSkippedDueToAlreadyMigrated: number;
  skierowaniaMigrated: number;
  skierowaniaMigrationErrorCount: number;
  skierowaniaStartDate?: string;
  skierowaniaEndDate?: string;
  skierowaniaEstimatdEnd?: string;
  skierowaniaPerMinute?: number;
}

export interface IdentyfikatorUslugobiorcy {
  rodzajIdentyfikatora?: RodzajIdentyfikatoraOsoby;
  identyfikator?: string;
  krajWystawieniaIdentyfikatora?: string;
}

export interface IdentyfikatorZewnetrzny {
  system?: string;
  id?: number;
}

export interface JednostkaOrganizacyjna {
  typSwiadczeniodawcy: TypSwiadczeniodawcy;
  kodResortowyI?: string;
  kodResortowyV?: string;
  kodResortowyVII?: string;
  kodResortowyVIII?: string;
  regon?: string;
  nazwaPodmiotu?: string;
  nazwaPlacowki?: string;
  nazwaJednostki?: string;
  nazwaKomorki?: string;
  telefon?: string;
  adres?: Adres;
  miejsceIzbyLekarskiej?: MiejsceOkregowejIzbyLekarskiej;
  nazwa?: string;
}

export interface JednostkaOrganizacyjnaKomorkaLubJednostka {
  nazwa?: string;
  kodResortowyV?: string;
  kodResortowyVII?: string;
}

export interface JednostkaOrganizacyjnaPodmiot {
  typSwiadczeniodawcy: TypSwiadczeniodawcy;
  nazwa?: string;
  kodResortowyI?: string;
  regon?: string;
}

export enum KodSekcjiSkierowania {
  SekcjaGlownaSkierowania = 0,
  Komentarze = 1,
  WynikSkaliBarthel = 2,
  WywiadZawodowy = 3,
  CzynnikiNarazeniaZawodowego = 4,
  DotychczasoweLeczenie = 5,
  Wywiad = 6,
  BadaniePrzedmiotowe = 7,
  AktualneWynikiBadan = 8,
  PowodSkierowania = 9,
  WywiadSpoleczny = 10,
  InformacjeIdentyfikacyjne = 11,
  OcenaSzkolna = 12,
  PrzeciwwskazaniaDoRehabilitacji = 13,
}

export interface KodSystemu {
  kod?: string;
  system?: string;
  systemKod?: string;
  nazwa?: string;
  tlumaczenie?: KodSystemu;
}

export enum KodWynikuWalidacji {
  BrakBledow = 0,
  Bledy = 1,
  Ostrzezenia = 2,
}

export const kodWynikuWalidacjiDescription = {
  [KodWynikuWalidacji.BrakBledow]: 'Brak błędów',
  [KodWynikuWalidacji.Bledy]: 'Błędy',
  [KodWynikuWalidacji.Ostrzezenia]: 'Ostrzeżenia',
};

export interface KomunikatWalidacji {
  typ: TypKomunikatuWalidacji;
  kod?: string;
  komunikat?: string;
  szczegoly?: string;
}

export interface KontekstPodmiotuLubPraktyki {
  typSwiadczeniodawcy: TypSwiadczeniodawcy;
  miejsceIzbyLekarskiej?: MiejsceOkregowejIzbyLekarskiej;
  kodResortowyI?: string;
  kodResortowyV?: string;
  kodResortowyVII?: string;
}

export interface KontekstUzyskaniaDostepuZm {
  podmiot?: Oid;
  uzytkownik?: Oid;
  dataUwierzytelnienia?: string;
  rolaUzytkownikaZm?: string;
  rolaUzytkownikaEdm?: string;
  trybDostepuDoDanych?: TrybDostepuDoDanych;
}

export interface KontekstWywolaniaEPuap {
  certyfikat?: EPuapKontekstWywolaniaEPuapCertyfikatIntegratora;
}

export interface KontekstZapytaniaZm {
  typSwiadczeniodawcy: TypSwiadczeniodawcy;
  miejsceIzbyLekarskiej?: MiejsceOkregowejIzbyLekarskiej;
  kodResortowyI?: string;
  kodResortowyV?: string;
  kodResortowyVII?: string;
  numerKontaUslugodawcy?: string;
  rolaUzytkownika?: ZmRolaUzytkownika;
  typIdentyfikatoraUzytkownika?: TypIdentyfikatoraPersoneluMedycznego;
  identyfikatorUzytkownika?: string;
  trybDostepuDoDanych?: TrybDostepuDoDanych;
  certyfikatTls?: Certyfikat;
  certyfikatWss?: Certyfikat;
}

export enum MiejsceOkregowejIzbyLekarskiej {
  Bialystok = 50,
  BielskoBiala = 51,
  Bydgoszcz = 52,
  Gdansk = 53,
  GorzowWielkopolski = 54,
  Katowice = 55,
  Kielce = 56,
  Krakow = 57,
  Lublin = 58,
  Lodz = 59,
  Olsztyn = 60,
  Opole = 61,
  Plock = 62,
  Poznan = 63,
  Rzeszow = 64,
  Szczecin = 65,
  Tarnow = 66,
  Torun = 67,
  Warszawa = 68,
  Wroclaw = 69,
  ZielonaGora = 70,
  WojskowaIzbaLekarska = 72,
  Koszalin = 74,
  Czestochowa = 75,
}

export interface Oid {
  root?: string;
  extension?: string;
}

export interface Opiekun {
  imie?: string;
  nazwisko?: string;
  rodzajIdentyfikatora?: RodzajIdentyfikatoraOsoby;
  identyfikator?: string;
  krajWystawieniaIdentyfikatora?: string;
}

export interface P1CommunicationContextCacheEntryDto {
  id: number;
  hash?: string;
  typSwiadczeniodawcy?: TypSwiadczeniodawcy;
  miejsceIzbyLekarskiej?: MiejsceOkregowejIzbyLekarskiej;
  kodResortowyI?: string;
  kodResortowyV?: string;
  kodResortowyVII?: string;
  numerKontaUslugodawcy: number;
  rolaUzytkownika?: number;
  typIdentyfikatoraUzytkownika?: TypIdentyfikatoraPersoneluMedycznego;
  identyfikatorUzytkownika?: string;
  imie?: string;
  nazwisko?: string;
  certyfikatTlsThumbprint?: string;
  certyfikatWssThumbprint?: string;
}

export interface Pacjent {
  id?: string;
  dodatkowyIdentyfikatorHl7?: Oid;
  imie?: string;
  nazwisko?: string;
  rodzajIdentyfikatora?: RodzajIdentyfikatoraOsoby;
  identyfikator?: string;
  opiekun?: Opiekun;
  numerKolejnegoUrodzenia?: number;
  krajWystawieniaIdentyfikatora?: string;
  plec: Plec;
  dataUrodzenia?: string;
  adres?: Adres;
  miejsceUrodzenia?: Adres;
  numerTelefonu?: string;
  email?: string;
  imieOjca?: string;
  imieMatki?: string;
  pacjentZOpiekunem?: boolean;
}

export interface PersonelMedyczny {
  imie?: string;
  nazwisko?: string;
  pesel?: string;
  npwz?: string;
  zawodMedyczny?: ZawodMedyczny;
  tytulNaukowy?: string;
  specjalnosci?: SpecjalnoscLekarza[];
}

export enum Plec {
  Nieokreslona = 0,
  Mezczyzna = 1,
  Kobieta = 2,
}

export interface ProceduraMedyczna {
  kod?: string;
  nazwa?: string;
}

export interface ReceptyAnulowanieReceptyResult {
  wynikOperacji?: WynikOperacji;
  wynikWalidacji?: WynikWalidacji;
  xml?: string;
}

export interface ReceptyDaneDoAnulowaniaReceptyZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: DaneDoAnulowaniaRecepty;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyDaneDoOdczytuPakietuReceptZp1 {
  kluczPakietu?: string;
}

export interface ReceptyDaneDoOdczytuPakietuReceptZp1ZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: ReceptyDaneDoOdczytuPakietuReceptZp1;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyDaneDoOdczytuReceptyZp1 {
  kluczRecepty?: string;
}

export interface ReceptyDaneDoOdczytuReceptyZp1ZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: ReceptyDaneDoOdczytuReceptyZp1;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyDaneDoWystawieniaPakietuRecept {
  identyfikatorZewnetrzny?: IdentyfikatorZewnetrzny;
  kodOddzialuNfz?: string;
  numerUmowyNfz?: string;
  kodOddzialuNfzPacjenta?: string;
  uprawnienieDodatkowe?: ReceptyUprawnienieDodatkowe;
  uprawnieniaDodatkowe?: ReceptyUprawnienieDodatkowe[];
  osobaWystawiajaca?: PersonelMedyczny;
  asystentMedyczny?: PersonelMedyczny;
  jednostkaOrganizacyjna?: JednostkaOrganizacyjna;
  pacjent?: Pacjent;
  recepty?: DaneDoWystawieniaRecepty[];
  certyfikatDoPodpisu?: Certyfikat;
}

export interface ReceptyDaneDoWystawieniaPakietuReceptListZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: ReceptyDaneDoWystawieniaPakietuRecept[];
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyDaneDoWystawieniaPakietuReceptXml {
  certyfikatDoPodpisu?: Certyfikat;
  identyfikatorZewnetrzny?: IdentyfikatorZewnetrzny;
  recepty?: ReceptyDaneDoWystawieniaReceptyXml[];
}

export interface ReceptyDaneDoWystawieniaPakietuReceptXmlZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: ReceptyDaneDoWystawieniaPakietuReceptXml;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyDaneDoWystawieniaPakietuReceptZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: ReceptyDaneDoWystawieniaPakietuRecept;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyDaneDoWystawieniaReceptyXml {
  identyfikatorZewnetrzny?: IdentyfikatorZewnetrzny;
  identyfikatorWinoskuZamowieniaRecepty?: string;
  xml?: string;
}

export interface ReceptyDaneWyszukiwaniaLokalnychRecept {
  statusPakietu?: ReceptyStatusPakietuRecept;
  dataOd?: string;
  dataDo?: string;
  kodDostepu?: string;
  kodPakietu?: string;
  kluczPakietu?: string;
  kluczRecepty?: string;
  idRecepty?: number;
  idZewnetrzneRecepty?: number;
  idPakietu?: number;
  idZewnetrznePakietu?: number;
  systemZewnetrzny?: string;
  anulowane?: boolean;
}

export interface ReceptyDaneWyszukiwaniaReceptUslugobiorcyRozszerzone {
  pacjent?: IdentyfikatorUslugobiorcy;
  dataOd?: string;
  dataDo?: string;
  nazwaLeku?: string;
  receptaIOD?: Oid;
  numerStrony?: number;
  sytuacjaZagrozeniaZycia?: boolean;
}

export interface ReceptyDaneWyszukiwaniaReceptUslugobiorcyRozszerzoneZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: ReceptyDaneWyszukiwaniaReceptUslugobiorcyRozszerzone;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyDaneWyszukiwaniaReceptWystawiajacego {
  nadanyIdDokumentuRecepty?: string;
  npwzOsobyWystawiajacej?: string;
}

export interface ReceptyDaneWyszukiwaniaReceptWystawiajacegoZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: ReceptyDaneWyszukiwaniaReceptWystawiajacego;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyGenerowanieDokumentowPakietuReceptResult {
  recepty?: ReceptyGenerowanieDokumentowPakietuReceptResultItem[];
}

export interface ReceptyGenerowanieDokumentowPakietuReceptResultItem {
  numerReceptyWPakiecie: number;
  xml?: string;
}

export enum ReceptyKategoriaDostepnosciLeku {
  Rp = 0,
  Rpw = 1,
  Rpz = 2,
}

export interface ReceptyKontekstWywolaniaP1 {
  numerKontaUslugodawcy?: string;
  typSwiadczeniodawcy?: TypSwiadczeniodawcy;
  miejsceIzbyLekarskiej?: MiejsceOkregowejIzbyLekarskiej;
  kodResortowyI?: string;
  kodResortowyV?: string;
  kodResortowyVII?: string;
  zawodMedyczny?: ZawodMedyczny;
  npwz?: string;
  certyfikatUwierzytelnieniaSystemu?: Certyfikat;
  certyfikatUwierzytelnieniaDanych?: Certyfikat;
  pesel?: string;
  peselAsystentaMedycznego?: string;
}

export interface ReceptyOdczytDanychDostepowychSkierowaniaDaneZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: SkierowaniaOdczytDanychDostepowychSkierowaniaDane;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyOdczytDokumentuSkierowaniaDaneZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: SkierowaniaOdczytDokumentuSkierowaniaDane;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyOdczytDokumentuSkierowaniaDoRealizacjiDaneZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: SkierowaniaOdczytDokumentuSkierowaniaDoRealizacjiDane;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyOdczytPakietuReceptZp1Result {
  wynikOperacji?: WynikOperacji;
  kluczPakietu?: string;
  kodPakietu?: string;
  recepty?: ReceptyOdczytPakietuReceptZp1ResultItem[];
}

export interface ReceptyOdczytPakietuReceptZp1ResultItem {
  kluczRecepty?: string;
  status: ReceptyStatusReceptyWp1;
  xml?: string;
}

export interface ReceptyOdczytReceptyZp1Result {
  wynikOperacji?: WynikOperacji;
  kluczRecepty?: string;
  status?: ReceptyStatusReceptyWp1;
  przyczynaZablokowaniaRecepty?: string;
  xml?: string;
}

export interface ReceptyOdmowaPrzyjeciaDoRealizacjiSkierowaniaDaneZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: SkierowaniaOdmowaPrzyjeciaDoRealizacjiSkierowaniaDane;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyOpisProduktu {
  nazwa?: string;
  moc?: string;
  postac?: string;
  szczegolowaWielkoscOpakowania?: ReceptyZawartoscOpakowania;
  wielkoscOpakowania?: string;
}

export interface ReceptyPakietRecept {
  id: number;
  status: ReceptyStatusPakietuRecept;
  kodPakietu?: string;
  kodDostepu?: string;
  kluczPakietu?: string;
  recepty?: ReceptyRecepta[];
}

export interface ReceptyPotwierdzenieIlosciSubstancjiCzynnej {
  nazwaSubstancji?: string;
  ilosc: number;
  jednostka?: string;
}

export enum ReceptyPoziomOdplatnosciZaLeki {
  Bezplatne = 0,
  Ryczalt = 1,
  _30Limitu = 2,
  _50Limitu = 3,
  Pelnoplatne = 4,
}

export interface ReceptyProduktLeczniczy {
  id: number;
  nazwa?: string;
  nazwaPowszechnieStosowana?: string;
  rodzajPreparatu?: string;
  moc?: string;
  postac?: string;
  podmiotOdpowiedzialny?: string;
  typProcedury?: string;
  numerPozwolenia?: string;
  waznoscPozwolenia?: string;
  kodAtc?: string;
  substancjeCzynne?: string[];
  opakowania?: ReceptyProduktLeczniczyOpakowanie[];
}

export interface ReceptyProduktLeczniczyOpakowanie {
  id: number;
  wielkosc: number;
  jednostkaWielkosci?: string;
  kodEan?: string;
  kategoriaDostepnosci?: string;
  skasowane?: boolean;
  numerEu?: string;
  dystrybutorRownolegly?: string;
  produkt?: ReceptyProduktLeczniczy;
}

export interface ReceptyPrzekazanieInformacjiOBrakachDokumentacjiSkierowaniaDaneZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: SkierowaniaPrzekazanieInformacjiOBrakachDokumentacjiSkierowaniaDane;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyPrzyjecieDoRealizacjiSkierowaniaDaneZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: SkierowaniaPrzyjecieDoRealizacjiSkierowaniaDane;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyRecepta {
  id: number;
  dataDodania: string;
  numerReceptyWPakiecie: number;
  kluczRecepty?: string;
  wynikWalidacji?: WynikWalidacji;
  anulowana?: boolean;
  identyfikatorDokumentu?: string;
  identyfikatorDokumentuRoot?: string;
  identyfikatorZbioruWersjiDokumentu?: string;
  identyfikatorZbioruWersjiDokumentuRoot?: string;
  xml?: string;
  xmlDokumentuAnulowania?: string;
}

export interface ReceptyRezygnacjaZRealizacjiSkierowaniaDaneZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: SkierowaniaRezygnacjaZRealizacjiSkierowaniaDane;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export enum ReceptyRodzajLeku {
  LekGotowy = 0,
  LekRecepturowy = 1,
  SrodekSpozywczySpecjalnegoPrzeznaczeniaZywieniowego = 2,
  WyrobMedyczny = 3,
}

export interface ReceptySkladnikReceptury {
  rplId?: number;
  kodEan?: string;
  nazwa?: string;
  iloscAdnotacja?: string;
  ilosc?: number;
  iloscJednostka?: string;
}

export enum ReceptyStatusPakietuRecept {
  PrzygotowanyDoWysylki = 0,
  PomyslnieWyslano = 1,
  BladWysylania = 2,
  PrzetwarzanieP1 = 3,
}

export const receptyStatusPakietuReceptDescription = {
  [ReceptyStatusPakietuRecept.PrzygotowanyDoWysylki]: 'Przygotowany do wysyłki',
  [ReceptyStatusPakietuRecept.PomyslnieWyslano]: 'Pomyślnie wysłano do P1',
  [ReceptyStatusPakietuRecept.BladWysylania]: 'Błąd wysyłania',
  [ReceptyStatusPakietuRecept.PrzetwarzanieP1]: 'Przetwarzanie w P1',
};

export enum ReceptyStatusReceptyWp1 {
  Wystawiona = 0,
  Zablokowana = 1,
  Zrealizowana = 2,
  CzesciowoZrealizowana = 3,
  Anulowana = 4,
}

export const receptyStatusReceptyWp1Description = {
  [ReceptyStatusReceptyWp1.Wystawiona]: 'Wystawiona',
  [ReceptyStatusReceptyWp1.Zablokowana]: 'Zablokowana',
  [ReceptyStatusReceptyWp1.Zrealizowana]: 'Zrealizowana',
  [ReceptyStatusReceptyWp1.CzesciowoZrealizowana]: 'Częściowo zrealizowana',
  [ReceptyStatusReceptyWp1.Anulowana]: 'Anulowana',
};

export interface ReceptyTestControllerXmlPayload {
  xml?: string;
}

export enum ReceptyUprawnienieDodatkowe {
  AZ = 0,
  BW = 1,
  CN = 2,
  DN = 3,
  IB = 4,
  IN = 5,
  IW = 6,
  PO = 7,
  WP = 8,
  ZK = 9,
  S_ = 10,
  C_ = 11,
  WE = 12,
}

export interface ReceptyWeryfikacjaPakietuReceptResult {
  wynikOperacji?: WynikOperacji;
  recepty?: WeryfikacjaPakietuReceptResultWynikWeryfikacjiRecepty[];
}

export interface ReceptyWynikWyszukiwaniaLokalnychRecept {
  totalCount: number;
  returnedCount: number;
  recepty?: ReceptyWynikWyszukiwaniaLokalnychReceptItem[];
}

export interface ReceptyWynikWyszukiwaniaLokalnychReceptItem {
  id: number;
  idPakietu: number;
  statusPakietu: ReceptyStatusPakietuRecept;
  identyfikatorZewnetrznyRecepty?: IdentyfikatorZewnetrzny;
  identyfikatorZewnetrznyPakietu?: IdentyfikatorZewnetrzny;
  dataDodania: string;
  kodPakietu?: string;
  kluczPakietu?: string;
  kluczRecepty?: string;
  anulowana?: boolean;
}

export interface ReceptyWyslijDokumentAnulowaniaReceptyDoPodpisuPzResult {
  ePuapUrl?: string;
}

export interface ReceptyWyslijPakietReceptDoPodpisuPzResult {
  wynikOperacji?: WynikOperacji;
  recepty?: WeryfikacjaPakietuReceptResultWynikWeryfikacjiRecepty[];
  ePuapUrl?: string;
}

export interface ReceptyWyslijWielePakietowReceptDoPodpisuPzResult {
  ePuapUrl?: string;
}

export interface ReceptyWyszukanieProduktowRplQuery {
  ean?: string;
  szukaj?: string;
  zwrocOpakowania?: boolean;
  zwrocWszystkie?: boolean;
}

export interface ReceptyWyszukanieReceptWystawiajacegoResult {
  wynikOperacji?: WynikOperacji;
  recepty?: ReceptyWyszukanieReceptWystawiajacegoResultItem[];
}

export interface ReceptyWyszukanieReceptWystawiajacegoResultItem {
  kluczRecepty?: string;
  kluczPakietu?: string;
}

export interface ReceptyWyszukanieSkierowanUslugobiorcyDaneZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: SkierowaniaWyszukanieSkierowanUslugobiorcyDane;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyWyszukanieSkierowanWystawiajacegoDaneZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: SkierowaniaWyszukanieSkierowanWystawiajacegoDane;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyWyszukiwanieProduktowRplResult {
  produkty?: ReceptyProduktLeczniczy[];
}

export interface ReceptyWyszukiwanieReceptUslugobiorcyRozszerzoneResult {
  wynikOperacji?: WynikOperacji;
  liczbaRecept: number;
  recepty?: ReceptyWyszukiwanieReceptUslugobiorcyRozszerzoneResultItem[];
}

export interface ReceptyWyszukiwanieReceptUslugobiorcyRozszerzoneResultItem {
  idReceptyUslugodawcy?: Oid;
  kluczRecepty?: string;
  status: ReceptyStatusReceptyWp1;
  dataWystawienia: string;
  nazwaLeku?: string;
  ean?: string;
  iloscOpakowan?: number;
  wielkoscOpakowania?: string;
  poziomOdplatnosci?: string;
  dataPierwszegoWydaniaLeku?: string;
  uprawnieniaDodatkowe?: string[];
  dataRealizacjiOd?: string;
  dataRealizacjiDo?: string;
  przyczynaZablokowaniaRecepty?: string;
}

export interface ReceptyZakonczenieRealizacjiSkierowaniaDaneZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: SkierowaniaZakonczenieRealizacjiSkierowaniaDane;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyZapisDokumentuAnulowaniaSkierowaniaDaneZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: SkierowaniaZapisDokumentuAnulowaniaSkierowaniaDane;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyZapisDokumentuSkierowaniaDaneZapytanieZKontekstemP1 {
  kontekstP1?: ReceptyKontekstWywolaniaP1;
  payload?: SkierowaniaZapisDokumentuSkierowaniaDane;
  kontekstEPuap?: KontekstWywolaniaEPuap;
}

export interface ReceptyZapisPakietuReceptResult {
  wynikOperacji?: WynikOperacji;
  pakiet?: ReceptyPakietRecept;
}

export interface ReceptyZawartoscOpakowania {
  ilosc?: number;
  jednostka?: string;
}

export enum RodzajDokumentuSkierowania {
  Skierowanie = 0,
  SkierowanieNaPielegniarskaOpiekeDlugoterminowa = 1,
  SkierowaniaDoSzpitalaPsychiatrycznego = 2,
  SkierowaniaDoUzdrowiskaLeczenie = 3,
  SkierowaniaDoUzdrowiskaRehabilitacja = 4,
  SkierowanieDoZakladuOpiekunczego = 5,
  SkierowanieNaBadaniaWZwiazkuZPodejrzeniemChorobyZawodowej = 6,
  SkierowanieNaRehabilitacje = 7,
  SkierowanieNaRehabilitacjeKardiologiczna = 8,
  SkierowanieNaRehabilitacjeNeurologiczna = 9,
  SkierowanieNaRehabilitacjePsychiatryczna = 10,
}

export enum RodzajIdentyfikatoraOsoby {
  Pesel = 1,
  DowodOsobisty = 2,
  PrawoJazdy = 3,
  Paszport = 4,
  KsiazeczkaZeglarska = 5,
  KrajowyIdentyfikator = 10,
  TymczasoweZaswiadczenieTozsamosciCudzoziemca = 11,
  NN = 12,
  NoworodekBezIdentyfikatoraIBezOpiekuna = 13,
  Opiekun = 99,
}

export const rodzajIdentyfikatoraOsobyDescription = {
  [RodzajIdentyfikatoraOsoby.Pesel]: 'Pesel',
  [RodzajIdentyfikatoraOsoby.DowodOsobisty]: 'Dowód osobisty',
  [RodzajIdentyfikatoraOsoby.PrawoJazdy]: 'Prawo jazdy',
  [RodzajIdentyfikatoraOsoby.Paszport]: 'Paszport',
  [RodzajIdentyfikatoraOsoby.KsiazeczkaZeglarska]: 'Książeczka żeglarska',
  [RodzajIdentyfikatoraOsoby.KrajowyIdentyfikator]: 'Krajowy identyfikator',
  [RodzajIdentyfikatoraOsoby.TymczasoweZaswiadczenieTozsamosciCudzoziemca]: 'Tymczasowe zaświadczenie tożsamości cudzoziemca',
  [RodzajIdentyfikatoraOsoby.NN]: 'Brak identyfikatora - NN',
  [RodzajIdentyfikatoraOsoby.NoworodekBezIdentyfikatoraIBezOpiekuna]: 'Noworodek bez własnego identyfikatora i bez opiekuna',
  [RodzajIdentyfikatoraOsoby.Opiekun]: 'Opiekun',
};

export interface ServerEnvironmentInfoDto {
  isProduction?: boolean;
  isDevelopment?: boolean;
  environmentName?: string;
  assemblyVersion?: string;
  p1DemoMode?: boolean;
}

export interface SkierowaniaIdentyfikatorOid {
  root?: string;
  extension?: string;
}

export interface SkierowaniaJednostkaObslugujaca {
  kod?: string;
  nazwa?: string;
  sugerowanyRealizator?: JednostkaOrganizacyjna;
  sugerowanyRealizatorOpis?: string;
}

export interface SkierowaniaOdczytDanychDostepowychSkierowaniaDane {
  skierowanieOID?: SkierowaniaIdentyfikatorOid;
}

export interface SkierowaniaOdczytDanychDostepowychSkierowaniaResult {
  wynikOperacji?: WynikOperacji;
  kluczSkierowania?: string;
  kodSkierowania?: string;
}

export interface SkierowaniaOdczytDokumentuSkierowaniaDane {
  skierowanieOID?: SkierowaniaIdentyfikatorOid;
}

export interface SkierowaniaOdczytDokumentuSkierowaniaDoRealizacjiDane {
  kluczSierowania?: string;
  kodSkierowania?: string;
}

export interface SkierowaniaOdczytDokumentuSkierowaniaDoRealizacjiResult {
  wynikOperacji?: WynikOperacji;
  statusSkierowania?: SkierowaniaStatusSkierowaniaP1;
  wynikWeryfikacji?: WynikWalidacji;
  komentarze?: SkierowaniaOdczytDokumentuSkierowaniaResultKomentarz[];
  kluczSkierowania?: string;
  skierowanie?: SkierowaniaSkierowanieDokument;
  html?: string;
}

export interface SkierowaniaOdczytDokumentuSkierowaniaResult {
  wynikOperacji?: WynikOperacji;
  statusSkierowania?: SkierowaniaStatusSkierowaniaP1;
  wynikWeryfikacji?: WynikWalidacji;
  komentarze?: SkierowaniaOdczytDokumentuSkierowaniaResultKomentarz[];
  kluczSkierowania?: string;
  skierowanie?: SkierowaniaSkierowanieDokument;
  html?: string;
}

export interface SkierowaniaOdczytDokumentuSkierowaniaResultKomentarz {
  dataWprowadzeniaDoSystemu: string;
  tresc?: string;
}

export interface SkierowaniaOdmowaPrzyjeciaDoRealizacjiSkierowaniaDane {
  kluczSkierowania?: string;
  komentarz?: string;
}

export interface SkierowaniaOdmowaPrzyjeciaDoRealizacjiSkierowaniaResult {
  wynikOperacji?: WynikOperacji;
}

export interface SkierowaniaParagraf {
  naglowek?: string;
  tekst?: string;
}

export interface SkierowaniaProcedura {
  kod?: string;
  nazwa?: string;
  nazwaUslugi?: string;
  uwagi?: string;
}

export interface SkierowaniaPrzekazanieInformacjiOBrakachDokumentacjiSkierowaniaDane {
  skierowanieOID?: SkierowaniaIdentyfikatorOid;
  komentarz?: string;
}

export interface SkierowaniaPrzekazanieInformacjiOBrakachDokumentacjiSkierowaniaResult {
  wynikOperacji?: WynikOperacji;
}

export interface SkierowaniaPrzyjecieDoRealizacjiSkierowaniaDane {
  kluczSierowania?: string;
  komentarz?: string;
  refundacja?: boolean;
}

export interface SkierowaniaPrzyjecieDoRealizacjiSkierowaniaResult {
  wynikOperacji?: WynikOperacji;
}

export interface SkierowaniaRezygnacjaZRealizacjiSkierowaniaDane {
  skierowanieOID?: SkierowaniaIdentyfikatorOid;
  komentarz?: string;
}

export interface SkierowaniaRezygnacjaZRealizacjiSkierowaniaResult {
  wynikOperacji?: WynikOperacji;
}

export interface SkierowaniaRozpoznanieDto {
  rozpoznanieKod?: string;
  rozpoznanieNazwa?: string;
  rozpoznanieOpis?: string;
}

export interface SkierowaniaSekcja {
  kod: KodSekcjiSkierowania;
  tytyl?: string;
  paragrafy?: SkierowaniaParagraf[];
}

export interface SkierowaniaSkierowanieBadaniePrzedmiotowe {
  cisnienieTetniczeSkurczowe?: number;
  cisnienieTetniczeRozkurczowe?: number;
  waga?: number;
  wzrost?: number;
  tetno?: number;
  skoraIWezly?: string;
  ukladOddechowy?: string;
  ukladKrazenia?: string;
  ukladTrawienny?: string;
  ukladMoczowy?: string;
  ukladRuchu?: string;
  zdolnoscDoSamoobslugi?: boolean;
  sprawnoscRuchowa?: string;
  ukladNerwowy?: string;
  przeciwskazaniaNaSurowceLecznicze?: boolean;
  uzasadnienie?: string;
}

export interface SkierowaniaSkierowanieDoUzdrowiska {
  daneOSzkole?: SkierowaniaParagraf[];
  informacjeDodatkowe?: SkierowaniaParagraf[];
  historia?: SkierowaniaSkierowanieDoUzdrowiskaHistoria;
  wynikiBadan?: ZestawWynikowBadan[];
  drogaKoresponcencjiZPacjentem: DrogaKorespondencji;
  badaniePrzedmiotowe?: SkierowaniaSkierowanieBadaniePrzedmiotowe;
}

export interface SkierowaniaSkierowanieDoUzdrowiskaHistoria {
  wywiad?: SkierowaniaParagraf[];
  ostatniiPobytUzdrowiskowy?: string;
  leczenieOknologiczne?: boolean;
  szczepienia?: ProceduraMedyczna[];
}

export interface SkierowaniaSkierowanieDokument {
  skierowanieOID?: SkierowaniaIdentyfikatorOid;
  identyfikatorZewnetrzny?: IdentyfikatorZewnetrzny;
  pacjent?: Pacjent;
  dataWystawienia: string;
  jednostkaObslugujaca?: SkierowaniaJednostkaObslugujaca;
  jednostkaWystawiajaca?: JednostkaOrganizacyjna;
  osobaWystawiajaca?: PersonelMedyczny;
  numerUmowyNfz?: string;
  kodOddzialuNfz?: string;
  uwagi?: string;
  procedury?: SkierowaniaProcedura[];
  rozpoznania?: SkierowaniaRozpoznanieDto[];
  rozpoznanieOpisowe?: string;
  czyPilne?: boolean;
  typSkierowania: SkierowaniaTypSkierowaniaWgP1;
  rodzajDokumentuSkierowania: RodzajDokumentuSkierowania;
  sekcjeOpisowe?: SkierowaniaSekcja[];
  skierowanieDoUzdrowiska?: SkierowaniaSkierowanieDoUzdrowiska;
  zalaczniki?: ZalacznikiZalacznikPowiazanie[];
  xml?: string;
  tytul?: string;
}

export interface SkierowaniaSkierowanieLokalneDto {
  idZewnetrzne?: number;
  systemZewnetrznyKod?: string;
  id: number;
  identyfikatorDokumentu?: string;
  identyfikatorDokumentuRoot?: string;
  identyfikatorZbioruWersjiDokumentu?: string;
  identyfikatorZbioruWersjiDokumentuRoot?: string;
  dataDodania: string;
  kluczSkierowania?: string;
  kodSkierowania?: string;
  operacjaAsyncId?: string;
  statusOperacji: StatusOperacjiP1;
  xml?: string;
  dataAnulowania?: string;
  anulowanieXml?: string;
  skierowanieHtml?: string;
  informacjeOSkierowaniuHtml?: string;
  skierowanieDane?: SkierowaniaSkierowanieDokument;
}

export interface SkierowaniaSkierowanieWystawione {
  idZewnetrzne?: number;
  systemZewnetrznyKod?: string;
  id: number;
  identyfikatorDokumentu?: string;
  identyfikatorDokumentuRoot?: string;
  identyfikatorZbioruWersjiDokumentu?: string;
  identyfikatorZbioruWersjiDokumentuRoot?: string;
  dataDodania: string;
  kluczSkierowania?: string;
  kodSkierowania?: string;
  operacjaAsyncId?: string;
  statusOperacji: StatusOperacjiP1;
  xml?: string;
  dataAnulowania?: string;
  anulowanieXml?: string;
}

export enum SkierowaniaStatusSkierowaniaP1 {
  WYSTAWIONE = 0,
  U_REALIZATORA = 1,
  ZREALIZOWANE = 2,
  ANULOWANE = 3,
  ODRZUCONE = 4,
  DO_UZUPELNIENIA = 5,
  OCZEKUJACE = 6,
  NIEZREALIZOWANE = 7,
  REZYGNACJA_DO_OCENY = 8,
}

export enum SkierowaniaTypSkierowaniaWgP1 {
  SkierowanieNaBadanieLubLeczenie = 0,
  ProsbaO_ObjecieOpieka = 1,
  SkierowanieNaKonsultacje = 2,
}

export interface SkierowaniaWeryfikacjaDokumentuSkierowaniaResult {
  wynikOperacji?: WynikOperacji;
  wynikWalidacji?: WynikWalidacji;
  xml?: string;
}

export interface SkierowaniaWyslijAnulowanieSkierowanieDoPodpisuPzResult {
  ePuapUrl?: string;
}

export interface SkierowaniaWyslijSkierowanieDoPodpisuPzResult {
  wynikOperacji?: WynikOperacji;
  wynikWalidacji?: WynikWalidacji;
  ePuapUrl?: string;
}

export interface SkierowaniaWyszukanieSkierowanUslugobiorcyDane {
  skierowanieOID?: SkierowaniaIdentyfikatorOid;
  uslugobiorcaOID?: SkierowaniaIdentyfikatorOid;
  pracownikWystawcyOID?: SkierowaniaIdentyfikatorOid;
  dataOd?: string;
  dataDo?: string;
  statusSkierowania?: SkierowaniaStatusSkierowaniaP1;
}

export interface SkierowaniaWyszukanieSkierowanUslugobiorcyResult {
  wynikOperacji?: WynikOperacji;
  wynikiWyszukiwania?: WyszukanieSkierowanUslugobiorcyResultWynikWyszukiwaniaSkierowania[];
}

export interface SkierowaniaWyszukanieSkierowanWystawiajacegoDane {
  skierowanieOID?: SkierowaniaIdentyfikatorOid;
  dataOd?: string;
  dataDo?: string;
  statusSkierowania?: SkierowaniaStatusSkierowaniaP1;
}

export interface SkierowaniaWyszukanieSkierowanWystawiajacegoResult {
  wynikOperacji?: WynikOperacji;
  wynikiWyszukiwania?: WyszukanieSkierowanWystawiajacegoResultWynikWyszukiwaniaSkierowania[];
}

export interface SkierowaniaZakonczenieRealizacjiSkierowaniaDane {
  skierowanieOID?: SkierowaniaIdentyfikatorOid;
  komentarz?: string;
  refundacjaParametry?: SkierowaniaZakonczenieRealizacjiSkierowaniaDaneRefundacja;
}

export interface SkierowaniaZakonczenieRealizacjiSkierowaniaDaneRefundacja {
  czyRefundacja?: boolean;
  platnikOID?: SkierowaniaIdentyfikatorOid;
}

export interface SkierowaniaZakonczenieRealizacjiSkierowaniaResult {
  wynikOperacji?: WynikOperacji;
}

export interface SkierowaniaZapisDokumentuAnulowaniaSkierowaniaDane {
  certyfikatDoPodpisu?: Certyfikat;
  identyfikatorZewnetrzny?: IdentyfikatorZewnetrzny;
  osobaWystawiajaca?: PersonelMedyczny;
  jednostkaOrganizacyjna?: JednostkaOrganizacyjna;
  pacjent?: Pacjent;
  uzasadnienie?: string;
}

export interface SkierowaniaZapisDokumentuAnulowaniaSkierowaniaResult {
  wynikOperacji?: WynikOperacji;
  wynikWalidacji?: WynikWalidacji;
  xml?: string;
}

export interface SkierowaniaZapisDokumentuSkierowaniaDane {
  skierowanieOID?: SkierowaniaIdentyfikatorOid;
  identyfikatorZewnetrzny?: IdentyfikatorZewnetrzny;
  pacjent?: Pacjent;
  dataWystawienia: string;
  jednostkaObslugujaca?: SkierowaniaJednostkaObslugujaca;
  jednostkaWystawiajaca?: JednostkaOrganizacyjna;
  osobaWystawiajaca?: PersonelMedyczny;
  numerUmowyNfz?: string;
  kodOddzialuNfz?: string;
  uwagi?: string;
  procedury?: SkierowaniaProcedura[];
  rozpoznania?: SkierowaniaRozpoznanieDto[];
  rozpoznanieOpisowe?: string;
  czyPilne?: boolean;
  typSkierowania: SkierowaniaTypSkierowaniaWgP1;
  rodzajDokumentuSkierowania: RodzajDokumentuSkierowania;
  sekcjeOpisowe?: SkierowaniaSekcja[];
  skierowanieDoUzdrowiska?: SkierowaniaSkierowanieDoUzdrowiska;
  zalaczniki?: ZalacznikiZalacznikPowiazanie[];
  xml?: string;
  tytul?: string;
  certyfikatDoPodpisu?: Certyfikat;
}

export interface SkierowaniaZapisDokumentuSkierowaniaResult {
  wynikOperacji?: WynikOperacji;
  wynikZapisu?: ZapisDokumentuSkierowaniaResultWynikZapisuSkierowania;
}

export interface SkierowaniaZapisDokumentuSkierowaniaResultKanalyKomunikacjiPacjenta {
  powiadomieniaEmail?: boolean;
  powiadomieniaSms?: boolean;
  kontoIkp?: boolean;
}

export enum SpecjalnoscLekarza {
  Alergologia = 0,
  AnestezjologiaIIntensywnaTerapia = 1,
  Angiologia = 2,
  AudiologiaIFoniatria = 3,
  BalneologiaIMedycynaFizykalna = 4,
  ChirurgiaDziecieca = 5,
  ChirurgiaKlatkiPiersiowej = 6,
  ChirurgiaNaczyniowa = 7,
  ChirurgiaOgolna = 8,
  ChirurgiaOnkologiczna = 9,
  ChirurgiaPlastyczna = 10,
  ChirurgiaSzczekowotwarzowa = 11,
  ChorobyPluc = 12,
  ChorobyPlucDzieci = 13,
  ChorobyWewnetrzne = 14,
  ChorobyZakazne = 15,
  DermatologiaIWenerologia = 16,
  Diabetologia = 17,
  DiagnostykaLaboratoryjna = 18,
  Endokrynologia = 19,
  EndokrynologiaGinekologicznaIRozrodczosc = 20,
  EndokrynologiaIDiabetologiaDziecieca = 21,
  Epidemiologia = 22,
  FarmakologiaKliniczna = 23,
  Gastroenterologia = 24,
  GastroenterologiaDziecieca = 25,
  GenetykaKliniczna = 26,
  Geriatria = 27,
  GinekologiaOnkologiczna = 28,
  Hematologia = 29,
  Hipertensjologia = 30,
  ImmunologiaKliniczna = 31,
  IntensywnaTerapia = 32,
  Kardiochirurgia = 33,
  Kardiologia = 34,
  KardiologiaDziecieca = 35,
  MedycynaLotnicza = 36,
  MedycynaMorskaITropikalna = 37,
  MedycynaNuklearna = 38,
  MedycynaPaliatywna = 39,
  MedycynaPracy = 40,
  MedycynaRatunkowa = 41,
  MedycynaRodzinna = 42,
  MedycynaSadowa = 43,
  MedycynaSportowa = 44,
  MikrobiologiaLekarska = 45,
  Nefrologia = 46,
  NefrologiaDziecieca = 47,
  Neonatologia = 48,
  Neurochirurgia = 49,
  Neurologia = 50,
  NeurologiaDziecieca = 51,
  Neuropatologia = 52,
  Okulistyka = 53,
  OnkologiaIHematologiaDziecieca = 54,
  OnkologiaKliniczna = 55,
  OrtopediaITraumatologiaNarzaduRuchu = 56,
  Otorynolaryngologia = 57,
  OtorynolaryngologiaDziecieca = 58,
  Patomorfologia = 59,
  Pediatria = 60,
  PediatriaMetaboliczna = 61,
  Perinatologia = 62,
  PoloznictwoIGinekologia = 63,
  Psychiatria = 64,
  PsychiatriaDzieciIMlodziezy = 65,
  RadiologiaIDiagnostykaObrazowa = 66,
  RadioterapiaOnkologiczna = 67,
  RehabilitacjaMedyczna = 68,
  Reumatologia = 69,
  Seksuologia = 70,
  ToksykologiaKliniczna = 71,
  TransfuzjologiaKliniczna = 72,
  TransplantologiaKliniczna = 73,
  Urologia = 74,
  UrologiaDziecieca = 75,
  ZdrowiePubliczne = 76,
}

export enum StatusOperacjiP1 {
  PrzygotowanyDoWysylki = 0,
  PomyslnieWyslano = 1,
  BladWysylania = 2,
  PrzetwarzanieP1 = 3,
  PrzychodzaceOdczytane = 100,
}

export enum TrybDostepuDoDanych {
  KontynuacjaLeczenia = 1,
  PilnyWCeluRatowaniaZycia = 2,
}

export enum TypCertyfikatu {
  P1TlsPodmiot = 1,
  P1WssPodmiot = 2,
}

export enum TypIdentyfikatoraPersoneluMedycznego {
  Npwz = 0,
  Pesel = 1,
}

export enum TypKomunikatuWalidacji {
  Informacja = 0,
  Blad = 1,
  Ostrzezenie = 2,
}

export const typKomunikatuWalidacjiDescription = {
  [TypKomunikatuWalidacji.Informacja]: 'Informacja',
  [TypKomunikatuWalidacji.Blad]: 'Błąd',
  [TypKomunikatuWalidacji.Ostrzezenie]: 'Ostrzeżenie',
};

export enum TypSwiadczeniodawcy {
  PodmiotLeczniczy = 0,
  PraktykaLekarska = 1,
}

export interface WeryfikacjaPakietuReceptResultWynikWeryfikacjiRecepty {
  numerReceptyWPakiecie: number;
  kluczRecepty?: string;
  opisProduktu?: ReceptyOpisProduktu;
  wynikWalidacji?: WynikWalidacji;
  xml?: string;
}

export interface WynikBadania {
  nazwa?: string;
  jednostka?: string;
  wartosc?: number;
  wartoscDolnyLimit?: number;
  wartoscGornyLimit?: number;
  wartoscOpisowa?: string;
  procedura?: ProceduraMedyczna;
}

export interface WynikOperacji {
  sukces?: boolean;
  kodBledu?: string;
  komunikatBledu?: string;
}

export interface WynikWalidacji {
  kod: KodWynikuWalidacji;
  komunikaty?: KomunikatWalidacji[];
}

export interface WyszukanieSkierowanUslugobiorcyResultWynikWyszukiwaniaSkierowania {
  dataWystawienia: string;
  skierowanieOid?: SkierowaniaIdentyfikatorOid;
  podmiotWystawcyOid?: SkierowaniaIdentyfikatorOid;
  pracownikWystawcyOid?: SkierowaniaIdentyfikatorOid;
  statusSkierowania: SkierowaniaStatusSkierowaniaP1;
  podmiotNazwa?: string;
  wystawcaNazwa?: string;
}

export interface WyszukanieSkierowanWystawiajacegoResultWynikWyszukiwaniaSkierowania {
  dataWystawienia: string;
  identyfikatorOID?: SkierowaniaIdentyfikatorOid;
  statusSkierowania: SkierowaniaStatusSkierowaniaP1;
}

export enum ZalacznikiRodzajZalacznika {
  WynikBadaniaLab = 1,
  WynikBadaniaEkg = 2,
  WynikBadaniaRtg = 3,
  WynikBadaniaDiagnostycznego = 4,
  KonsultacjaLekarska = 5,
}

export const zalacznikiRodzajZalacznikaDescription = {
  [ZalacznikiRodzajZalacznika.WynikBadaniaLab]: 'Wynik badania laboratoryjnego',
  [ZalacznikiRodzajZalacznika.WynikBadaniaEkg]: 'Wynik badania EKG',
  [ZalacznikiRodzajZalacznika.WynikBadaniaRtg]: 'Wynik badania RTG',
  [ZalacznikiRodzajZalacznika.WynikBadaniaDiagnostycznego]: 'Wynik badania diagnostycznego',
  [ZalacznikiRodzajZalacznika.KonsultacjaLekarska]: 'Konsultacja lekarska',
};

export interface ZalacznikiZalacznikPowiazanie {
  id?: Oid;
  setId?: Oid;
  wersja?: number;
  nazwa?: string;
  rodzaj?: ZalacznikiRodzajZalacznika;
  kod?: KodSystemu;
}

export interface ZapisDokumentuSkierowaniaResultWynikZapisuSkierowania {
  kluczSkierowania?: string;
  kodSkierowania?: string;
  kanalyKomunikacjiPacjenta?: SkierowaniaZapisDokumentuSkierowaniaResultKanalyKomunikacjiPacjenta;
  wynikWalidacji?: WynikWalidacji;
  skierowanieOID?: SkierowaniaIdentyfikatorOid;
  skierowanieId?: number;
  xml?: string;
}

export enum ZawodMedyczny {
  AsystentkaDentystyczna = 1,
  DiagnostaLaboratoryjny = 2,
  Dietetyk = 3,
  Farmaceuta = 4,
  Felczer = 5,
  Fizjoterapeuta = 6,
  HigienistkaDentystyczna = 7,
  HigienistkaSzkolna = 8,
  InstruktorHigieny = 9,
  Lekarz = 11,
  LekarzDentysta = 12,
  Logopeda = 13,
  Masazysta = 14,
  OpiekunkaDziecieca = 15,
  Optometrysta = 16,
  Ortoptystka = 17,
  Pielegniarka = 18,
  Polozna = 19,
  ProtetykSluchu = 20,
  Psychoterapeuta = 21,
  RatownikMedyczny = 22,
  SpecjalistaZdrowiaPublicznego = 23,
  TechnikAnalitykiMedycznej = 24,
  TechnikDentystyczny = 25,
  TechnikFarmaceutyczny = 26,
  TechnikElektronikiMedycznej = 27,
  TechnikElektroradiolog = 28,
  TechnikOptyk = 29,
  TechnikOrtopeda = 30,
  TerapeutaZajeciowy = 31,
  OpiekunMedyczny = 32,
  InstruktorTerapiiUzaleznien = 33,
  SpecjalistaPsychoterapiiUzaleznien = 34,
  PedagogSpecjalny = 35,
  TerapeutaSrodowiskowy = 36,
  Psycholog = 50,
}

export interface ZdarzeniaMedyczneTestControllerProvenanceCriteria {
  target?: string;
  patient?: string;
}

export interface ZdarzeniaMedyczneTestControllerRawFhirResponse {
  statusCode: number;
  response?: string;
}

export interface ZestawWynikowBadan {
  nazwa?: string;
  procedura?: ProceduraMedyczna;
  dataWykonania: string;
  wynikiBadan?: WynikBadania[];
  wynikBadaniaProsty?: string;
}

export interface ZgodyPacjentaAutoryzujZadanieNadaniaUprawnienDoDanychPacjentaKryteria {
  idWniosku?: string;
  kodAutoryzacyjny?: string;
}

export interface ZgodyPacjentaAutoryzujZadanieNadaniaUprawnienDoDanychPacjentaKryteriaZgodaPacjentaRequest {
  kontekst?: ZgodyPacjentaKontekstPracownikaMedycznego;
  payload?: ZgodyPacjentaAutoryzujZadanieNadaniaUprawnienDoDanychPacjentaKryteria;
}

export interface ZgodyPacjentaAutoryzujZadanieNadaniaUprawnienDoDanychPacjentaResponse {
  idWniosku?: string;
  nadanoUprawenie?: boolean;
}

export interface ZgodyPacjentaDanePracownika {
  rolaPracownikaMedycznego: ZgodyPacjentaRolaPracownikaMedycznego;
  identyfikatorPracownika?: string;
}

export interface ZgodyPacjentaDokumentZgodyNaSwiadczenieMedyczne {
  tytulZgody?: string;
  dataWystawienia: string;
  trescZgody?: string;
  swiadczeniobiorca?: ZgodyPacjentaPacjentDokumentuZgodyNaSwiadczenieMedyczne;
  opisRozpoznania?: string;
  opisZabiegu?: string;
  dataPlanowanegoZabiegu?: string;
  podmiotSwiadczeniodawcy?: ZgodyPacjentaPodmiotSwiadczeniodawcyDokumentuZgodyNaSwiadczenieMedyczne;
  pracownikMedyczny?: ZgodyPacjentaPracownikMedycznyDokumentuZgodyNaSwiadczenieMedyczne;
  oswiadczenieDoWyboru?: ZgodyPacjentaOswiadczeniaDoWyboruDlaDokumentuZgodyNaSwiadczenieMedyczne;
}

export interface ZgodyPacjentaIdentyfikatorPracownika {
  npwz?: string;
  typPracownika: ZgodyPacjentaTypPracownikaMedycznegoDokumentuZgodyNaSwiadczenieMedyczne;
}

export interface ZgodyPacjentaIdentyfikatorPracownikaRejestrujacegoZgode {
  identyfikator?: string;
  rolaPracownikaMedycznego?: ZgodyPacjentaRolaPracownikaMedycznego;
}

export interface ZgodyPacjentaKontekstPracownikaMedycznego {
  typSwiadczeniodawcy: TypSwiadczeniodawcy;
  miejsceIzbyLekarskiej?: MiejsceOkregowejIzbyLekarskiej;
  kodResortowyI?: string;
  kodResortowyV?: string;
  kodResortowyVII?: string;
  rolaPracownikaMedycznego?: ZgodyPacjentaRolaPracownikaMedycznego;
  identyfikatorPracownika?: string;
  certyfikatTls?: Certyfikat;
  certyfikatWss?: Certyfikat;
}

export interface ZgodyPacjentaOdczytZgodyNaDostepDoDokumentacjiMedycznejPacjenta {
  zgodaUdzielona?: boolean;
  waznoscNaDataCzas: string;
  wynikOdczytuZgody: ZgodyPacjentaWynikOdczytuZgody;
}

export interface ZgodyPacjentaOdczytZgodyNaDostepDoInformacjiOStanieZdrowiaPacjenta {
  zgodaUdzielona?: boolean;
  waznoscNaDataCzas: string;
  wynikOdczytuZgody: ZgodyPacjentaWynikOdczytuZgody;
}

export interface ZgodyPacjentaOdczytZgodyNaSwiadczenieMedyczneKryteria {
  numerZgody?: string;
}

export interface ZgodyPacjentaOdczytZgodyNaSwiadczenieMedyczneKryteriaZgodaPacjentaRequest {
  kontekst?: ZgodyPacjentaKontekstPracownikaMedycznego;
  payload?: ZgodyPacjentaOdczytZgodyNaSwiadczenieMedyczneKryteria;
}

export interface ZgodyPacjentaOdczytZgodyNaSwiadczenieMedyczneResponse {
  numerZgody?: string;
  dataAkceptacji?: string;
  identyfikatoraOsobyAkceptujacej?: string;
  dokumentZgody?: ZgodyPacjentaDokumentZgodyNaSwiadczenieMedyczne;
  zgodaUdzielona?: boolean;
}

export interface ZgodyPacjentaOdczytajKanalyKomunikacjiPacjentaKryteria {
  identyfikator?: string;
  rodzajIdentyfikatora: RodzajIdentyfikatoraOsoby;
}

export interface ZgodyPacjentaOdczytajKanalyKomunikacjiPacjentaKryteriaZgodaPacjentaRequest {
  kontekst?: ZgodyPacjentaKontekstPracownikaMedycznego;
  payload?: ZgodyPacjentaOdczytajKanalyKomunikacjiPacjentaKryteria;
}

export interface ZgodyPacjentaOdczytajKanalyKomunikacjiPacjentaResponse {
  czyPowiadomieniaEmail?: boolean;
  czyPowiadomieniaSMS?: boolean;
  czyKontoIKP?: boolean;
}

export interface ZgodyPacjentaOsobaWeryfikowana {
  imie?: string;
  nazwisko?: string;
  identyfikator?: string;
  rodzajIdentyfikatora: RodzajIdentyfikatoraOsoby;
}

export interface ZgodyPacjentaOswiadczeniaDlaDokumentuZgodyNaSwiadczenieMedyczne {
  identyfikatorOswiadczenia: number;
  trescOswiadczenia?: string;
  czyZaznaczono?: boolean;
}

export interface ZgodyPacjentaOswiadczeniaDoWyboruDlaDokumentuZgodyNaSwiadczenieMedyczne {
  ograniczWyborOswiadczenDoJednokrontego?: boolean;
  typWyboruOswiadczen: ZgodyPacjentaTypWyboruOswiadczeniaDokumnetuZgodyNaSwiadczenieMedyczne;
  oswiadczenia?: ZgodyPacjentaOswiadczeniaDlaDokumentuZgodyNaSwiadczenieMedyczne[];
}

export interface ZgodyPacjentaPacjent {
  identyfikator?: string;
  rodzajIdentyfikatora: RodzajIdentyfikatoraOsoby;
}

export interface ZgodyPacjentaPacjentDokumentuZgodyNaSwiadczenieMedyczne {
  pesel?: string;
  imieSwiadczeniobiorcy?: string;
  nazwiskoSwiadczeniobiorcy?: string;
  dataUrodzeniaSwiadczeniobiorcy: string;
}

export interface ZgodyPacjentaPersonelWeryfikowany {
  imie?: string;
  nazwisko?: string;
  idnetyfikator?: string;
  typIdentyfikatora: TypIdentyfikatoraPersoneluMedycznego;
}

export interface ZgodyPacjentaPodmiotSwiadczeniodawcyDokumentuZgodyNaSwiadczenieMedyczne {
  typSwiadczeniodawcy: TypSwiadczeniodawcy;
  miejsceIzbyLekarskiej?: MiejsceOkregowejIzbyLekarskiej;
  kodResortowyI?: string;
  kodResortowyV?: string;
  kodResortowyVII?: string;
  nazwaPodmiotuSwiadczeniodawcy?: string;
}

export interface ZgodyPacjentaPodmiotWeryfikowany {
  nazwa?: string;
  nazwaKomorkiLubJednostki?: string;
  adres?: Adres;
  identyfikatorPodmiotu?: KontekstPodmiotuLubPraktyki;
}

export interface ZgodyPacjentaPracownikMedycznyDokumentuZgodyNaSwiadczenieMedyczne {
  danePracownikaMedycznego?: string;
  imiePracownikaMedycznego?: string;
  nazwiskoPracownikaMedycznego?: string;
  npwzPracownikaMedyczneg?: string;
  typPracownikaMedycznego: ZgodyPacjentaTypPracownikaMedycznegoDokumentuZgodyNaSwiadczenieMedyczne;
}

export enum ZgodyPacjentaRolaPracownikaMedycznego {
  Lekarz = 1,
  LekarzDentysta = 2,
  Felczer = 3,
  Pielegniarka = 4,
  Polozna = 5,
  Farmaceuta = 6,
  TechnikFarmaceutoyczny = 7,
  ProfesjonalistaMedyczny = 8,
  PracownikAdministracyjny = 9,
  DiagnostaLaboratoryjny = 10,
}

export enum ZgodyPacjentaStatusZgodyNaSwiadczenieMedyczne {
  WYSTAWIONA = 0,
  PODPISANA = 1,
  ODEBRANA = 2,
  WYCOFANA = 3,
}

export enum ZgodyPacjentaTypPracownikaMedycznegoDokumentuZgodyNaSwiadczenieMedyczne {
  LEK = 0,
  LEKD = 1,
  PIEL = 2,
  POL = 3,
  FEL = 4,
}

export enum ZgodyPacjentaTypWyboruOswiadczeniaDokumnetuZgodyNaSwiadczenieMedyczne {
  JEDNOKROTNY = 0,
  WIELOKROTNY = 1,
}

export enum ZgodyPacjentaWynikOdczytuZgody {
  ZGODA_UDZIELONA = 0,
  BRAK_ZAREJESTROWANEJ_ZGODY = 1,
  BRAK_AKTUALNEJ_ZGODY = 2,
}

export const zgodyPacjentaWynikOdczytuZgodyDescription = {
  [ZgodyPacjentaWynikOdczytuZgody.ZGODA_UDZIELONA]: 'Zgoda udzielona',
  [ZgodyPacjentaWynikOdczytuZgody.BRAK_ZAREJESTROWANEJ_ZGODY]: 'Brak zarejestrowanej zgody',
  [ZgodyPacjentaWynikOdczytuZgody.BRAK_AKTUALNEJ_ZGODY]: 'Brak aktualnej zgody',
};

export interface ZgodyPacjentaWynikWyszukiwaniaZgodyNaSwiadczenieMedyczneResponse {
  dataAkceptacji?: string;
  dataRejestracji: string;
  peselSwiadczeniobiorcy?: string;
  numerZgody?: string;
  statusZgodyNaSwiadczenieMedyczne: ZgodyPacjentaStatusZgodyNaSwiadczenieMedyczne;
  zgodaUdzielona?: boolean;
}

export interface ZgodyPacjentaWyslijWniosekONadanieUprawnienDoDanychPacjentaKryteria {
  peselPacjenta?: string;
  peselOsobyAutoryzujacejWniosek?: string;
}

export interface ZgodyPacjentaWyslijWniosekONadanieUprawnienDoDanychPacjentaKryteriaZgodaPacjentaRequest {
  kontekst?: ZgodyPacjentaKontekstPracownikaMedycznego;
  payload?: ZgodyPacjentaWyslijWniosekONadanieUprawnienDoDanychPacjentaKryteria;
}

export interface ZgodyPacjentaWyslijWniosekONadanieUprawnienDoDanychPacjentaResponse {
  idWniosku?: string;
}

export interface ZgodyPacjentaWyszukiwanieZgodyNaSwiadczenieMedyczneKryteria {
  numerZgody?: string;
  dataAkceptacjiDo?: string;
  dataAkceptacjiOd?: string;
  dataRejestracjiDo?: string;
  dataRejestracjiOd?: string;
  statusZgodyNaSwiadczenieMedyczne?: ZgodyPacjentaStatusZgodyNaSwiadczenieMedyczne;
  identyfikatorPracownika?: ZgodyPacjentaIdentyfikatorPracownika;
  identyfikatorPracownikaRejestrujacegoZgode?: ZgodyPacjentaIdentyfikatorPracownikaRejestrujacegoZgode;
}

export interface ZgodyPacjentaWyszukiwanieZgodyNaSwiadczenieMedyczneKryteriaZgodaPacjentaRequest {
  kontekst?: ZgodyPacjentaKontekstPracownikaMedycznego;
  payload?: ZgodyPacjentaWyszukiwanieZgodyNaSwiadczenieMedyczneKryteria;
}

export interface ZgodyPacjentaWyszukiwanieZgodyNaSwiadczenieMedyczneResponse {
  opisProblemu?: string;
  kodProblemu?: string;
  listaZgodNaSwiadczenieMedyczne?: ZgodyPacjentaWynikWyszukiwaniaZgodyNaSwiadczenieMedyczneResponse[];
}

export interface ZgodyPacjentaZapisZgodyNaSwiadczenieMedyczneKryteria {
  dokument?: ZgodyPacjentaDokumentZgodyNaSwiadczenieMedyczne;
}

export interface ZgodyPacjentaZapisZgodyNaSwiadczenieMedyczneKryteriaZgodaPacjentaRequest {
  kontekst?: ZgodyPacjentaKontekstPracownikaMedycznego;
  payload?: ZgodyPacjentaZapisZgodyNaSwiadczenieMedyczneKryteria;
}

export interface ZgodyPacjentaZapisZgodyNaSwiadczenieMedyczneResponse {
  opisProblemu?: string;
  kodProblemu?: string;
  czyZapisanoDokumentZgody?: boolean;
  numerZgody?: string;
}

export interface ZgodyPacjentaZgodaPacjentaNaDostepDoDanychKryteria {
  peselPacjenta?: string;
  idnetyfiaktorOsoby?: string;
  rodzajIdentyfikacjiOsoby: RodzajIdentyfikatoraOsoby;
  waznoscNaDataCzas: string;
}

export interface ZgodyPacjentaZgodaPacjentaNaDostepDoDanychKryteriaZgodaPacjentaRequest {
  kontekst?: ZgodyPacjentaKontekstPracownikaMedycznego;
  payload?: ZgodyPacjentaZgodaPacjentaNaDostepDoDanychKryteria;
}

export interface ZgodyPacjentaZgodaPacjentaNaDostepDoDanychPacjentaResponse {
  odczytZgodyNaDostepDoInformacjiOStanieZdrowiaPacjenta?: ZgodyPacjentaOdczytZgodyNaDostepDoInformacjiOStanieZdrowiaPacjenta;
  odczytZgodyNaDostepDoDokumentacjiMedycznejPacjenta?: ZgodyPacjentaOdczytZgodyNaDostepDoDokumentacjiMedycznejPacjenta;
  osobaWeryfikowana?: ZgodyPacjentaOsobaWeryfikowana;
  pacjent?: ZgodyPacjentaPacjent;
}

export interface ZgodyPacjentaZgodaPacjentaNaDostepDoDokumentacjiPacjentaDlaPracownikaPodmiotuKryteria {
  daneWeryfikowanegoPodmiotu?: KontekstPodmiotuLubPraktyki;
  daneWeryfikowanegoPracownika?: ZgodyPacjentaDanePracownika;
  peselPacjenta?: string;
  waznoscNaDataCzas: string;
}

export interface ZgodyPacjentaZgodaPacjentaNaDostepDoDokumentacjiPacjentaDlaPracownikaPodmiotuKryteriaZgodaPacjentaRequest {
  kontekst?: ZgodyPacjentaKontekstPracownikaMedycznego;
  payload?: ZgodyPacjentaZgodaPacjentaNaDostepDoDokumentacjiPacjentaDlaPracownikaPodmiotuKryteria;
}

export interface ZgodyPacjentaZgodaPacjentaNaDostepDoDokumentacjiPacjentaDlaPracownikaPodmiotuResponse {
  zgodaUdzielona?: boolean;
  waznoscNaDataCzas: string;
  wynikOdczytuZgody: ZgodyPacjentaWynikOdczytuZgody;
  weryfikowanyPersonel?: ZgodyPacjentaPersonelWeryfikowany;
  weryfikowanyPodmito?: ZgodyPacjentaPodmiotWeryfikowany;
  pacjent?: ZgodyPacjentaPacjent;
}

export interface ZmDaneDoPodpisuZasobuPrzezPracownika {
  idRekorduDoPodpisu?: number;
  idZasobu?: number;
  typZasobu?: string;
  pracownikPodpisujacy?: PersonelMedyczny;
  certyfikatPracownika?: Certyfikat;
}

export interface ZmDaneDoPodpisuZasobuPrzezPracownikaZmRequest {
  kontekst?: KontekstZapytaniaZm;
  payload?: ZmDaneDoPodpisuZasobuPrzezPracownika;
}

export interface ZmIdentyfikatorDokumentuSkierowujacego {
  rodzajIdentyfikatora: ZmRodzajIdentyfikatoraDokumentuSkierowujacego;
  system?: string;
  identyfikator?: string;
}

export interface ZmIdentyfikatorZasobuDoPodpisuPrzezPracownika {
  idRekorduDoPodpisu?: number;
  idZasobu?: number;
  typZasobu?: string;
}

export interface ZmInformacjaOCiazy {
  dataObserwacji: string;
  personel?: PersonelMedyczny;
  status: ZmInformacjaOCiazyStatusCiazy;
  szacowanaDataUrodzenia?: string;
  sposobOszacowaniaDatyUrodzenia?: ZmInformacjaOCiazyPodstawaKalkukacjiDatyUrodzenia;
  ciazaMnoga?: boolean;
  zakonczenieCiazy?: ZmInformacjaOCiazyPowodZakonczeniaCiazy;
}

export enum ZmInformacjaOCiazyPodstawaKalkukacjiDatyUrodzenia {
  Badanie = 0,
  OstatniaMenstruacja = 1,
  OstatniaOwulacja = 2,
}

export enum ZmInformacjaOCiazyPowodZakonczeniaCiazy {
  Porod = 0,
  PorodMartwy = 1,
  Inny = 2,
}

export enum ZmInformacjaOCiazyStatusCiazy {
  Nieznane = 0,
  Ciaza = 1,
  BrakCiazy = 2,
}

export interface ZmInt64ZmRequest {
  kontekst?: KontekstZapytaniaZm;
  payload: number;
}

export interface ZmListaNaglowkowDokumentowCriteria {
  idZasobuZdarzeniaMedycznego?: number;
  rodzajIdentyfikatoraPacjenta?: RodzajIdentyfikatoraOsoby;
  identyfikatorPacjenta?: string;
  krajWystawieniaIdentyfikatora?: string;
  zdarzenieOid?: Oid;
}

export interface ZmListaNaglowkowDokumentowCriteriaZmRequest {
  kontekst?: KontekstZapytaniaZm;
  payload?: ZmListaNaglowkowDokumentowCriteria;
}

export interface ZmListaNaglowkowDokumentowResponse {
  kontekstUzyskaniaDostepu?: KontekstUzyskaniaDostepuZm;
  total?: number;
  items?: ZmListaNaglowkowDokumentowResponseItem[];
}

export interface ZmListaNaglowkowDokumentowResponseItem {
  idZasobu: string;
  idWSystemieUslugodawcy?: Oid;
  dataUtworzenia: string;
  nazwa?: string;
  statusDostepnosci?: string;
}

export interface ZmListaZasobowDoPodpisuPracownika {
  items?: ZmListaZasobowDoPodpisuPracownikaItem[];
  total: number;
}

export interface ZmListaZasobowDoPodpisuPracownikaCriteria {
  idZasobuZdarzeniaMedycznego?: number;
  npwz?: string;
  pesel?: string;
  zwrocRowniezPodpisane?: boolean;
  zwrocPozostawioneBezPodpisu?: boolean;
  zwrocDaneXmlZasobu?: boolean;
  pageSize?: number;
  page?: number;
}

export interface ZmListaZasobowDoPodpisuPracownikaItem {
  id: number;
  idZasobuZdarzeniaMedycznego: number;
  idZasobu: number;
  typZasobu?: string;
  profilZasobu?: string;
  profilZasobuOpis?: string;
  podsumowanieZasobu?: string;
  pacjent?: Pacjent;
  pracownik?: PersonelMedyczny;
  dataZdarzenia?: string;
  dataZasobu?: string;
  podpisany?: boolean;
  xml?: string;
}

export interface ZmListaZdarzenMedycznychCriteria {
  rodzajIdentyfikatoraPacjenta: RodzajIdentyfikatoraOsoby;
  identyfikatorPacjenta?: string;
  dataOd?: string;
  dataDo?: string;
  zdarzenieOid?: Oid;
  limitLiczbyZdarzen?: number;
  sortujOdNajnowszych?: boolean;
}

export interface ZmListaZdarzenMedycznychCriteriaZmRequest {
  kontekst?: KontekstZapytaniaZm;
  payload?: ZmListaZdarzenMedycznychCriteria;
}

export interface ZmListaZdarzenMedycznychResponse {
  kontekstUzyskaniaDostepu?: KontekstUzyskaniaDostepuZm;
  total?: number;
  items?: ZmListaZdarzenMedycznychResponseItem[];
}

export interface ZmListaZdarzenMedycznychResponseItem {
  idZasobu?: string;
  idZasobuAsNumber?: number;
  dataOd: string;
  dataDo?: string;
  miejsceSwiadczenia?: string;
  typZdarzenia?: string;
  pacjentOid?: Oid;
  epizodOid?: Oid;
}

export interface ZmPacjentZmRequest {
  kontekst?: KontekstZapytaniaZm;
  payload?: Pacjent;
}

export interface ZmPodpisZasobuPrzezPracownikaResponse {
  sukces?: boolean;
  komunikaty?: KomunikatWalidacji[];
}

export interface ZmProcedura {
  kod?: string;
  nazwa?: string;
  data?: string;
  personelRealizujacy?: PersonelMedyczny;
  miejsceRealizacji?: JednostkaOrganizacyjnaKomorkaLubJednostka;
}

export interface ZmProfilaktyka40Plus {
  dataPomiarow?: string;
  personel?: PersonelMedyczny;
  waga?: number;
  wzrost?: number;
  bmi?: number;
  obwodWPasie?: number;
  cisnienieKrwiSkurczowe?: number;
  cisnienieKrwiRozkurczowe?: number;
  miarowoscRytmuSerca?: ZmProfilaktyka40PlusOcenaMiarowosciRytmuSerca;
}

export enum ZmProfilaktyka40PlusOcenaMiarowosciRytmuSerca {
  Miarowy = 1,
  Niemiarowy = 2,
}

export const zmProfilaktyka40PlusOcenaMiarowosciRytmuSercaDescription = {
  [ZmProfilaktyka40PlusOcenaMiarowosciRytmuSerca.Miarowy]: 'Rytm serca miarowy',
  [ZmProfilaktyka40PlusOcenaMiarowosciRytmuSerca.Niemiarowy]: 'Rytm serca niemiarowy',
};

export interface ZmProvenanceCriteriaZmRequest {
  kontekst?: KontekstZapytaniaZm;
  payload?: ZdarzeniaMedyczneTestControllerProvenanceCriteria;
}

export enum ZmPunktZdarzeniaMedycznego {
  Przyjecie = 0,
  Wypis = 1,
}

export enum ZmRodzajIdentyfikatoraDokumentuSkierowujacego {
  SkierowanieElektroniczne = 0,
  SkierowaniePapierowe = 1,
  ZlecenieElektroniczne = 2,
  ZleceniePapierowe = 3,
}

export enum ZmRodzajKodowaniaRozpoznania {
  ICD10 = 0,
  ICF = 1,
}

export enum ZmRolaUzytkownika {
  Lekarz = 1,
  LekarzDentysta = 2,
  Felczer = 3,
  Pielegniarka = 4,
  Polozna = 5,
  Farmaceuta = 6,
  RatownikMedyczny = 7,
  ProfesjonalistaMedyczny = 8,
  PracownikAdministracyjny = 9,
  DiagnostaLaboratoryjny = 10,
  Fizjoterapetua = 11,
}

export interface ZmRozliczenieNfz {
  miesiacRozliczeniowy?: string;
  dataPrzygotowania?: string;
  numerUmowyNfz?: string;
  pozycje?: ZmRozliczenieNfzPozycja[];
}

export interface ZmRozliczenieNfzPozycja {
  dataOd?: string;
  dataDo?: string;
  kodZakresuSwiadczen?: string;
  kodProduktu?: string;
  wyroznik?: string;
  krotnosc: number;
  krotnoscFaktyczna: number;
  wagaPuntkowa: number;
  ratujaceZycie?: boolean;
  cenaZaPunkt: number;
  taryfa?: number;
}

export interface ZmRozpoznanie {
  typ: ZmTypRozpoznania;
  rodzajKodu: ZmRodzajKodowaniaRozpoznania;
  kod?: string;
  nazwa?: string;
  data?: string;
  punktZdarzeniaMedycznego?: ZmPunktZdarzeniaMedycznego;
  personelStwierdzajacy?: PersonelMedyczny;
  miejsceStwierdzenia?: JednostkaOrganizacyjnaKomorkaLubJednostka;
  diagnozyPsychologiczne?: ZmRozpoznanieDiagnozaPsychologiczna[];
}

export interface ZmRozpoznanieDiagnozaPsychologiczna {
  kod?: string;
  nazwa?: string;
}

export enum ZmStatusZdarzenia {
  WTrakcie = 3,
  Zakonczone = 5,
}

export const zmStatusZdarzeniaDescription = {
  [ZmStatusZdarzenia.WTrakcie]: 'W trakcie',
  [ZmStatusZdarzenia.Zakonczone]: 'Zakończone',
};

export interface ZmStringZmRequest {
  kontekst?: KontekstZapytaniaZm;
  payload?: string;
}

export enum ZmTypRozpoznania {
  Glowne = 0,
  Wspolistniejace = 1,
}

export enum ZmTypZdarzenia {
  PobytWOddzialeSzpitalnym = 1,
  LeczenieJednegoDnia = 2,
  Pobyt = 3,
  Porada = 4,
  PoradaPatronazowa = 5,
  Wizyta = 6,
  WizytaPatronazowa = 7,
  CyklLeczenia = 8,
  Badanie = 9,
  Sesja = 10,
  OsobaLeczona = 11,
  WyjazdRatowniczy = 12,
  AkcjaRatownicza = 13,
  TransportSanitarny = 14,
  TransportLotniczy = 15,
  Hemodializa = 16,
  BilansZdrowia = 17,
  WyrobMedyczny = 18,
  Szczepienie = 19,
  BadanieTestPrzesiewowe = 20,
  SwiadczenieProfilaktyczne = 21,
  OsobaObjetaOpiekaKoordynowana = 22,
}

export const zmTypZdarzeniaDescription = {
  [ZmTypZdarzenia.PobytWOddzialeSzpitalnym]: 'Pobyt w oddziale szpitalnym',
  [ZmTypZdarzenia.LeczenieJednegoDnia]: 'Leczenie jednego dnia',
  [ZmTypZdarzenia.Pobyt]: 'Pobyt',
  [ZmTypZdarzenia.Porada]: 'Porada',
  [ZmTypZdarzenia.PoradaPatronazowa]: 'Porada patronażowa',
  [ZmTypZdarzenia.Wizyta]: 'Wizyta',
  [ZmTypZdarzenia.WizytaPatronazowa]: 'Wizyta patronażowa',
  [ZmTypZdarzenia.CyklLeczenia]: 'Cykl leczenia',
  [ZmTypZdarzenia.Badanie]: 'Badanie',
  [ZmTypZdarzenia.Sesja]: 'Sesja',
  [ZmTypZdarzenia.OsobaLeczona]: 'Osoba leczona',
  [ZmTypZdarzenia.WyjazdRatowniczy]: 'Wyjazd ratowniczy',
  [ZmTypZdarzenia.AkcjaRatownicza]: 'Akcja ratownicza',
  [ZmTypZdarzenia.TransportSanitarny]: 'Transport sanitarny',
  [ZmTypZdarzenia.TransportLotniczy]: 'Transport lotniczy',
  [ZmTypZdarzenia.Hemodializa]: 'Hemodializa',
  [ZmTypZdarzenia.BilansZdrowia]: 'Bilans zdrowia',
  [ZmTypZdarzenia.WyrobMedyczny]: 'Wyrob medyczny',
  [ZmTypZdarzenia.Szczepienie]: 'Szczepienie',
  [ZmTypZdarzenia.BadanieTestPrzesiewowe]: 'Badanie (test) przesiewowe',
  [ZmTypZdarzenia.SwiadczenieProfilaktyczne]: 'Świadczenie profilaktyczne',
  [ZmTypZdarzenia.OsobaObjetaOpiekaKoordynowana]: 'Osoba objęta opieką koordynowaną',
};

export interface ZmUbezpieczenie {
  dataOd?: string;
  dataDo?: string;
  kodPlatnika?: string;
  kodUprawnienia?: string;
  kodDokumentu?: string;
  oswiadczenie?: ZmUbezpieczenieDaneOswiadczenia;
}

export interface ZmUbezpieczenieDaneOsobySkladajacejOswiadczenie {
  imie?: string;
  nazwisko?: string;
  rodzajIdentyfikatora?: RodzajIdentyfikatoraOsoby;
  identyfikator?: string;
  krajWystawieniaIdentyfikatora?: string;
  adres?: Adres;
}

export interface ZmUbezpieczenieDaneOswiadczenia {
  numerOswiadczenia?: string;
  data?: string;
  kodOswiadczenia?: string;
  kodSposobuZlozeniaOswiadczenia?: string;
  kodSposobuPotwierdzeniaTozsamosci?: string;
  osobaSkladajacaOswiadczenie?: ZmUbezpieczenieDaneOsobySkladajacejOswiadczenie;
}

export interface ZmUrzadzenieMedyczne {
  personel?: PersonelMedyczny;
  kod?: string;
  nazwa?: string;
}

export interface ZmZdarzenieMedyczne {
  idEpizodu?: string;
  status: ZmStatusZdarzenia;
  typ: ZmTypZdarzenia;
  pacjent?: Pacjent;
  kodTrybuPrzyjeciaDoSzpitala?: string;
  kodTrybuWypisuZeSzpitala?: string;
  kodOddzialuNfz?: string;
  profilaktyka40Plus?: ZmProfilaktyka40Plus;
  informacjaOCiazy?: ZmInformacjaOCiazy;
  urzadzeniaMedyczne?: ZmUrzadzenieMedyczne[];
  podmiotUslugodawca?: JednostkaOrganizacyjnaPodmiot;
  dataOd?: string;
  dataDo?: string;
  miejscaUdzielaniaSwiadczen?: ZmZdarzenieMedyczneMiejsceSwiadczenia[];
  personel?: PersonelMedyczny[];
  ubezpieczenie?: ZmUbezpieczenie[];
  rozpoznania?: ZmRozpoznanie[];
  procedury?: ZmProcedura[];
  rozliczenieNfz?: ZmRozliczenieNfz;
  dokumentSkierowujacy?: ZmIdentyfikatorDokumentuSkierowujacego[];
}

export interface ZmZdarzenieMedyczneDaneZp1 {
  idEpizodu?: string;
  status: ZmStatusZdarzenia;
  typ: ZmTypZdarzenia;
  pacjent?: Pacjent;
  podmiotUslugodawca?: JednostkaOrganizacyjnaPodmiot;
  dataOd?: string;
  dataDo?: string;
  identyfikatorySkierowan?: Oid[];
  miejscaUdzielaniaSwiadczen?: ZmZdarzenieMedyczneMiejsceSwiadczenia[];
  personel?: PersonelMedyczny[];
  rozpoznania?: ZmRozpoznanie[];
  procedury?: ZmProcedura[];
  naglowkiDokumentow?: ZmListaNaglowkowDokumentowResponseItem[];
  bledyOdczytu?: string[];
  zasoby?: ZmZdarzenieMedyczneDaneZp1SuroweDaneZasobu[];
}

export interface ZmZdarzenieMedyczneDaneZp1SuroweDaneZasobu {
  idZasobu?: string;
  typZasobu?: string;
  xml?: string;
}

export interface ZmZdarzenieMedyczneMiejsceSwiadczenia {
  jednostka?: JednostkaOrganizacyjnaKomorkaLubJednostka;
  dataOd?: string;
  dataDo?: string;
}

export interface ZmZdarzenieMedyczneUsun {
  idZasobu?: number;
  idEpizodu?: string;
  pacjent?: Pacjent;
}

export interface ZmZdarzenieMedyczneUsunZmRequest {
  kontekst?: KontekstZapytaniaZm;
  payload?: ZmZdarzenieMedyczneUsun;
}

export interface ZmZdarzenieMedyczneWynikPrzeslania {
  idZasobu: number;
  pacjentOid?: Oid;
  epizodOid?: Oid;
  komunikaty?: KomunikatWalidacji[];
  zasobyDoPodpisania?: ZmListaZasobowDoPodpisuPracownikaItem[];
}

export interface ZmZdarzenieMedyczneZmRequest {
  kontekst?: KontekstZapytaniaZm;
  payload?: ZmZdarzenieMedyczne;
}
